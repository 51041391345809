import axios from 'axios'
import storePaymentDetails from '../../lib/payment/storePaymentDetails'

function serialize (vueInstance) {
  const donation = vueInstance.donationStore.donationParams
  return axios.post(
    `${process.env.VUE_APP_BACKEND_HOST}/paypal/serialize`,
    {
      currency: donation.currency,
      amount: donation.amount,
      location: vueInstance.$l10n.currentLocation.key,
      language: vueInstance.$l10n.currentLanguage,
      type: donation.type,
      return_url: `${location.origin}/callbacks/paypal/authorised?l=${vueInstance.$l10n.currentLocation.key}&paypal_redirect=true`
    }).then((response) => {
      const details = { paypalId: response.data.paypal_id }
      vueInstance.paymentStore.setDetails(details)

      storePaymentDetails(vueInstance, localStorage)
      const storedPayment = vueInstance.paymentStore.details

      if (storedPayment) {
        return { approveUrl: response.data.approve }
      } else {
        return Promise.reject(new Error('Payment could not be stored'))
      }
    }).then(data => {
      window.location.href = data.approveUrl
      return new Promise(() => setTimeout(() => { console.log('Wait for window loaction change') }, 10000))
    }
  )
}

export { serialize }
